





























import breakpoints from 'library/assets/vars/breakpoints'
export default

	props: block: Object

	data: ->
		carouselIndex: 0

		config:
			slidesPerPage: 1
			showDots: false
			showArrows: true
			gutter: 'var(--card-gutter)'
			peekLeft: 'var(--peek)'
			peekRight: 'var(--peek)'
			responsive: [
				{
					minWidth: parseInt breakpoints.desktop
					feather: 'var(--gutter)'
				}
				{
					maxWidth: parseInt breakpoints['mobile-landscape']
					feather: 'var(--feather-mobile)'
				}
			]

	methods:

		onCarouselChange: ({index}) -> @carouselIndex = index

		navClick: (index) -> @$refs.carousel.goto(index)

