



























import { mountComponent } from 'library/helpers/dom'
import VideoModal from 'library/components/globals/modals/video'

export default

	components: { VideoModal }

	props:
		poster: Array
		videoUrl: String

	methods:
		playVideo: ->
			await mountComponent VideoModal,
				parent: this
				propsData: { videoUrl: @videoUrl }


