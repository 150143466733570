







































import breakpoints from 'library/assets/vars/breakpoints'
export default

	props:
		items: Array
		carouselIndex: Number
		naturalNav:
			type: Boolean
			default: false

	data: ->
		config:
			slidesPerPage: 3
			showDots: false
			showArrows: false
			gutter: 'var(--card-gutter)'
			peekLeft: 'var(--gutter)'
			peekRight: 'var(--peek-right)'
			responsive: [
				{
					minWidth: parseInt breakpoints.desktop + 1
					slidesPerPage: 7
					feather: 'var(--gutter)'
				}
				{
					maxWidth: parseInt breakpoints.desktop
					slidesPerPage: 7
				}
				{
					maxWidth: parseInt breakpoints['tablet-landscape']
					slidesPerPage: 5
				}
				{
					maxWidth: parseInt breakpoints['tablet']
					slidesPerPage: 4
				}
				{
					maxWidth: parseInt breakpoints['mobile-landscape']
					slidesPerPage: 3
				}
				{
					maxWidth: parseInt breakpoints['mobile']
					slidesPerPage: 2
				}
			]

	methods:
		itemClick: (index) -> @$emit 'click', index

	watch:
		carouselIndex: ->
			if !@naturalNav
				@$refs.carousel.goto @carouselIndex


